<template>
  <div class="bg-white p-1 rounded" style=" border: solid 2px #e4e4e4;">
    <quill-editor
      class="dok-editor"
      :content="text"
      :placeholder="holder"
      :options="editorOptions"
      @change="setChange($event)"
    />
    <div class="flex justify-left p-2" v-if="recognition !== false">
      <button
        @click="!speachOn ? startSpeech() : stopSpeech()"
        class="flex flex-row items-center gap-x-2 bg-transparent font-EffraR border-0 cursor-pointer"
      >
        <img src="/svg/mic.svg" :class="speachOn ? 'animate-ping' : ''" />
        Dictez
      </button>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // import styles

export default {
  props: {
    content: {
      validator: prop => typeof prop === "string" || prop === null,
      required: true
    },
    classes: {
      type: String,
      required: false
    },
    holder: {
      type: String,
      required: false
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      MAX_CHAR_TO_SAVE: 5,
      lastValue: this.content,
      text: null,
      speachOn: false,
      recognition: false,
      editorOptions: {
        theme: "snow",
        placeholder: "Saisissez votre texte ...",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ["link", "image"]
          ]
        }
      }
    };
  },
  watch: {
    text(newVal, oldVal) {
      this.$emit("update:content", this.text);
      var lengthLastVal = this.lastValue ? this.lastValue.length : 0;
      if (
        newVal &&
        Math.abs(newVal.length - lengthLastVal) >= this.MAX_CHAR_TO_SAVE
      ) {
        this.lastValue = newVal;
        // this.$emit("saveValue", newVal);
      }
    }
  },
  created() {
    this.text = this.content;

    if ("webkitSpeechRecognition" in window) {
      this.recognition = new webkitSpeechRecognition();
      this.$nextTick(() => {
        this.recognition.lang = "fr-FR";
      });
    }
  },
  mounted() {
    if (this.recognition) {
      this.recognition.onresult = event => {
        let identificated = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          identificated = event.results[i][0].transcript;
          this.text += identificated;
        }
        this.speachOn = false;
        this.$forceUpdate();
      };
    }
  },
  methods: {
    startSpeech() {
      if (this.recognition) {
        this.recognition.start();
        this.speachOn = true;
      }
    },
    stopSpeech() {
      if (this.recognition) {
        this.recognition.stop();
        this.speachOn = false;
      }
    },
    setChange({ html }) {
      this.text = html;
    }
  }
};
</script>

<style lang="scss">
.dok-editor > .ql-toolbar.ql-snow {
  border: 0 !important;
}

.dok-editor > .ql-container.ql-snow {
  border: 0 !important;
}
</style>
