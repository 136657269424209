<template>
  <div class="w-full bg-white rounded-lg flex flex-col gap-y-0">
    <!-- --------------------HEADER-------------------- -->
    <header
      class="
        h-[105px]
        flex flex-row
        gap-x-4
        items-center
        px-8
        border-0 border-b border-solid border-gray-200
      "
    >
      <!-- IF NOT OPEN MESSAGE  -->
      <div
        class="flex justify-between items-center gap-x-2 w-full"
        v-if="!isOpenMessage"
      >
        <h1 class="text-2xl font-EffraM">
          {{ type === "INBOX" ? "Mails reçus" : "Mails envoyés" }}
        </h1>
        <dok-button @click.native="syncEmails">
          <i class="fas fa-sync"></i>
        </dok-button>
      </div>
      <!-- IF OPEN MESSAGE  -->
      <template v-if="isOpenMessage">
        <div class="flex flex-row justify-between items-center w-full">
          <div class="flex flex-row gap-x-2 items-center">
            <button
              class="
                border-0
                bg-transparent
                cursor-pointer
                hover:text-dokBlue-ultra
              "
              @click="closeMessage"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-corner-up-left"
              >
                <polyline points="9 14 4 9 9 4"></polyline>
                <path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
              </svg>
            </button>
            <!-- AVATAR -->
            <div
              class="
                bg-dokBlue-lighter
                h-[55px]
                w-[55px]
                border border-solid border-gray-100
                rounded-full
                overflow-hidden
              "
            >
              <img src="/no-avatar.png" class="h-full w-full object-cover" />
            </div>
            <div class="flex flex-col">
              <!-- FULL NAME -->

              <h1 class="font-EffraR text-lg">
                {{
                  message.from.value[0].name
                    ? message.from.value[0].name
                    : message.from.value[0].address
                }}
              </h1>
              <!-- EMAIL ADDRESS -->
              <span class="font-EffraR text-base">{{
                message.from.value[0].address || ""
              }}</span>
            </div>
          </div>
          <!-- SUBJECT -->

          <h1 class="font-EffraR">
            {{ message.subject || "--" }}
          </h1>
        </div>
      </template>
    </header>
    <!-- --------------------END HEADER-------------------- -->

    <!-- --------------------CONTENT-------------------- -->
    <main
      class="
        flex-1
        max-h-[450px]
        overflow-hidden overflow-y-auto
        divide-x-0 divide-y divide-solid divide-gray-200
      "
    >
      <!-- IF NOT OPEN MESSAGE  -->
      <template v-if="!isOpenMessage">
        <div
          class="min-h-300 w-full flex items-center justify-center"
          v-if="!listInbox.length"
        >
          <img src="/svg/request-pana.svg" class="max-h-300" />
        </div>
        <div
          v-for="(msg, index) in listInbox"
          :key="index"
          @click="openMessage(msg)"
          class="
            bg-white
            hover:bg-dokBlue-lighter
            grid grid-cols-4
            items-center
            gap-x-2
            py-3
            px-1
            cursor-pointer
          "
        >
          <!-- SUBJECT -->
          <div class="font-EffraR flex items-center gap-x-2">
            <div
              class="
                bg-dokBlue-lighter
                h-[30px]
                w-[30px]
                border border-solid border-gray-100
                rounded-full
                overflow-hidden
              "
            >
              <img src="/no-avatar.png" class="h-full w-full object-cover" />
            </div>
            <span class="font-EffraM text-lg">
              <template v-if="type !== 'SENT'">
                {{
                  msg.from.value[0].name
                    ? msg.from.value[0].name
                    : msg.from.value[0].address
                }}
              </template>
              <template v-if="type === 'SENT'">
                {{ msg.to.value[0].address }}
              </template>
            </span>
          </div>
          <!-- SHORT CONTENT -->
          <div class="flex items-center gap-x-1 col-span-2">
            <b class="text-black font-EffraR text-base">{{ msg.subject }}</b>
            -
            <span class="text-gray-500 font-EffraR text-sm"
              >{{ msg.text.substr(0, 40) }}...</span
            >
          </div>
          <!-- DATE -->
          <div class="text-left font-EffraR text-sm text-gray-500">
            {{ msg.date | moment("from", "now") }}
          </div>
        </div>
      </template>
      <!-- IF OPEN MESSAGE  -->
      <template v-if="isOpenMessage">
        <template>
          <div
            class="p-5 font-EffraR text-lg leading-relaxed"
            v-html="message.html || '--'"
          ></div>
        </template>
      </template>
    </main>
    <!-- --------------------END CONTENT-------------------- -->

    <!-- --------------------FOOTER-------------------- -->
    <div
      v-if="isOpenMessage"
      class="
        min-h-[112px]
        flex flex-row
        items-start
        gap-x-6
        px-8
        py-3
        border-0 border-t border-solid border-gray-200
      "
    >
      <speach-textarea
        id="spechText"
        :content.sync="sent.body"
        holder="Insert text here or dictate by clicking on the button below"
        class="w-full"
      ></speach-textarea>

      <dok-button
        bg="blue"
        size="lg"
        custom-class="flex items-center gap-x-2 px-8"
        @click.native="sentMessage"
      >
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Envoyer
      </dok-button>
    </div>
    <!-- --------------------END FOOTER-------------------- -->
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import speachTextarea from "@/views/global-components/spechToTextarea";

export default {
  components: { speachTextarea },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Boolean's
      isOpenMessage: false,
      // Array's
      listInbox: [],
      // Object's
      message: {},
    };
  },
  watch: {
    type(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isOpenMessage = false;
        this.getListMessages();
      }
    },
  },
  created() {
    this.getListMessages();
  },
  methods: {
    // Get List Messages
    async getListMessages() {
      this.$vs.loading();
      const { data } = await this.$http.get("doctors/mails/list", {
        params: { box: this.type },
      });

      console.log(data);

      if (data.ok) {
        this.listInbox = data.data;

        this.$nextTick(() => {
          this.$vs.loading.close();
        });

        return;
      }
      this.$vs.loading.close();
    },
    // Sent Message & Replay Message
    async sentMessage() {
      console.log(this.sent);
      if (!this.sent.email || !this.sent.subject || !this.sent.body) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Veuillez remplir les champs manquants",
        });
      }

      this.$vs.loading();
      const { data } = await this.$http.post("doctors/mails/send", {
        to: this.sent.email,
        subject: this.sent.subject,
        body: this.sent.body,
      });

      if (data.ok) {
        this.sent = { email: null, subject: null, body: null };
        this.getListMessages();
        this.$nextTick(() => {
          this.$vs.loading.close();
        });

        return EventBus.$emit("flashDok", {
          type: "success",
          message: "Votre email a été envoyé avec succès",
        });
      }

      this.$vs.loading.close();
    },
    // Open Message
    openMessage(msg) {
      if (!msg._id || !msg.from.value[0].address) return;

      this.sent = {
        email: msg.from.value[0].address,
        subject: `${msg.subject}`,
        body: null,
      };

      this.message = msg;
      this.$nextTick(() => {
        this.isOpenMessage = true;
      });
    },
    //  Close Message
    closeMessage() {
      this.sent = {};
      this.message = {};

      this.$nextTick(() => {
        this.isOpenMessage = false;
      });
    },
    syncEmails() {
      this.$store.dispatch("user/SYNC_EMAILS");
      this.getListMessages();
    },
  },
};
</script>
