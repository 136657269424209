<template>
  <div class="py-[32px] px-[42px] flex flex-row items-start gap-x-6">
    <!-------------------------------------------------- LEFT BLOCK -------------------------------------------------->
    <div class="w-[400px] min-w-[400px] max-w-[400px] bg-white rounded-lg">
      <!-- HEADER -->
      <div
        class="h-[105px] grid grid-cols-1 gap-x-2 items-center px-8 border-0 border-b border-solid border-gray-200"
      >
        <!-- New Message -->
        <dok-button
          @click.native="openModalSentMessage"
          custom-class="font-EffraM text-base"
          size="md"
          aria-label="Sent Message"
        >
          Nouveau email
        </dok-button>
      </div>
      <!-- IF CURRENT TAB IS EMAILS -->
      <div class="flex flex-col">
        <!-- INBOX TAB -->
        <div
          class="h-[64px] px-8 flex items-center justify-between cursor-pointer"
          :class="
            emailCurrentNav === 'INBOX'
              ? 'bg-dokBlue-lighter border-0 border-l-4 border-solid border-dokBlue-ultra font-EffraM'
              : 'hover:bg-dokBlue-lighter font-EffraR'
          "
          @click="emailCurrentNav = 'INBOX'"
        >
          Mails reçus
        </div>
        <!------------ LINE BETWEEN ------------>
        <div class="h-px border-0 border-b border-solid border-gray-200"></div>
        <!------------ END LINE BETWEEN ------------>
        <!-- SENT TAB -->
        <div
          class="h-[64px] px-8 flex items-center justify-between cursor-pointer"
          :class="
            emailCurrentNav === 'SENT'
              ? 'bg-dokBlue-lighter border-0 border-l-4 border-solid border-dokBlue-ultra font-EffraM'
              : 'hover:bg-dokBlue-lighter font-EffraR'
          "
          @click="emailCurrentNav = 'SENT'"
        >
          Mails envoyés
        </div>
      </div>
    </div>
    <!-------------------------------------------------- RIGHT BLOCK -------------------------------------------------->
    <emails-list :type="emailCurrentNav" />
    <!-- ------------------------ MODAL SENT NEW MESSAGE ---------------------------- -->
    <modal
      :isActive="modal"
      :exitModal="() => {}"
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <div class="flex justify-between items-center px-5 pt-5">
        <h1 class="font-EffraM text-3xl text-graylabel">Nouveau E-mail</h1>

        <dok-button custom-class="p-0 m-0" bg="none" @click.native="closeModal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M18 6L6 18M6 6l12 12"
            />
          </svg>
        </dok-button>
      </div>
      <div class="flex flex-col gap-y-3 p-5">
        <div class="flex flex-col relative">
          <!-- EMAIL -->
          <dok-input
            :d-model.sync="sent.email"
            label="Email :"
            size="md"
            d-placeholder="Adresse mail"
            custom-class="rounded-b-none w-full"
          ></dok-input>
          <!-- LIST DOCTOR'S -->
          <div
            v-if="currentListDoctors.length"
            class="w-full max-h-200 overflow-hidden overflow-y-scroll scroll px-0 border border-t-0 border-solid border-gray-200 rounded-b-lg divide-x-0 divide-y divide-solid divide-gray-200"
          >
            <div
              v-for="doctor in currentListDoctors"
              :key="doctor._id"
              @click="onSetDoctorEmail(doctor)"
              class="py-2 px-2 flex flex-row items-center gap-x-2 cursor-pointer"
            >
              <img
                :src="$checkAvatar(doctor.avatar)"
                class="w-10 h-10 rounded-full object-cover bg-dokBlue-lighterrr"
              />

              <h1 class="font-EffraR text-base">
                {{ `${doctor.firstName} ${doctor.lastName}` }}
              </h1>
            </div>
          </div>
        </div>
        <!-- Subject -->
        <div class="flex flex-col">
          <dok-input
            :d-model.sync="sent.subject"
            label="Objet :"
            size="md"
            d-placeholder="Objet du mail"
          ></dok-input>
        </div>
        <!-- BODY -->
        <div class="flex flex-col">
          <dok-textarea
            :d-model.sync="sent.body"
            label="Message :"
            size="md"
            d-placeholder=""
          ></dok-textarea>
        </div>
        <!--  ACTION -->
        <div class="flex justify-end">
          <dok-button
            size="md"
            custom-class="px-20 flex items-center gap-x-2 font-EffraM"
            @click.native="sentMessage"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z"
              />
            </svg>

            Envoyer
          </dok-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
// Emails
import emailsList from "./layouts/emailsList.vue";

// Components
import modal from "@/views/global-components/modal";

// Eventbus & vuex
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      emailCurrentNav: "INBOX",
      sent: {},
      currentListDoctors: [],
      modal: false
    };
  },
  components: {
    emailsList,
    modal
  },
  created() {
    // this.listDoctors();
  },
  watch: {
    "sent.email"(newVal, oldVal) {
      if (newVal.length && newVal !== oldVal) {
        this.listDoctors();
      }
    }
  },
  methods: {
    ...mapActions({ getDoctors: "user/GET_ALL_DOCTORS" }),

    // Get List Doctors
    async listDoctors() {
      await this.getDoctors({
        params: { term: this.sent.email || "" },
        onData: ({ ok, data }) => {
          if (ok) {
            this.currentListDoctors = data;
          }
        }
      });
    },
    // Sent Message & Replay Message
    async sentMessage() {
      console.log(this.sent);
      if (!this.sent.email || !this.sent.subject || !this.sent.body) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Veuillez remplir les champs manquants"
        });
      }

      this.$vs.loading();
      const { data } = await this.$http.post("doctors/mails/send", {
        to: this.sent.email,
        subject: this.sent.subject,
        body: this.sent.body
      });

      if (data.ok) {
        this.sent = { email: null, subject: null, body: null };
        this.$nextTick(() => {
          if (this.modal) this.modal = false;
          this.$vs.loading.close();
        });

        return EventBus.$emit("flashDok", {
          type: "success",
          message: "Votre email a été envoyé avec succès"
        });
      }

      this.$vs.loading.close();
    },
    // Use Doctor Email
    onSetDoctorEmail(doc) {
      event.preventDefault();

      if (doc.email) {
        this.sent.email = doc.email;
        this.$nextTick(() => {
          this.$forceUpdate();
        });
        return;
      }
      return EventBus.$emit("flashDok", {
        type: "errorr",
        message: "Adresse mail incorrecte"
      });
    },
    // Modal Open
    openModalSentMessage() {
      this.sent = {};
      this.$nextTick(() => {
        this.modal = true;
      });
    },
    // Model Close
    closeModal() {
      this.sent = {};
      this.$nextTick(() => {
        this.modal = false;
      });
    }
  }
};
</script>

<style></style>
